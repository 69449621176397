import { ArrowPathIcon, CheckCircleIcon, ShieldCheckIcon, ClipboardDocumentListIcon, SquaresPlusIcon, Cog8ToothIcon, EnvelopeIcon, LockClosedIcon, PaintBrushIcon, UsersIcon, WindowIcon, IdentificationIcon, BanknotesIcon } from "@heroicons/react/24/outline";
import { CameraIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAuthorizedUser } from "src/actions/auth";
import { getWorkspaceDetails } from "src/actions/workspace";
import BackIndicator from "src/components/Navigation/BackIndicator";
import PageList from "src/components/Pages/PageList";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Tabs from "src/components/Shared/Tabs/Tabs";
import WorkspaceGroups from "src/components/Workspaces/Workspace/Groups/WorkspaceGroups";
import WorkspacePdpList from "src/components/Workspaces/Workspace/Pdp/WorkspacePdpList";
import WorkspacePermissions from "src/components/Workspaces/Workspace/Permissions/WorkspacePermissions";
import WorkspaceSettings from "src/components/Workspaces/Workspace/Settings/WorkspaceSettings";
import WorkspaceLayoutStyles from "src/components/Workspaces/Workspace/WorkspaceLayoutStyles/WorkspaceLayoutStyles";
import PillEntry from "src/components/Workspaces/PillEntry";
import { baseUrl, noLogo } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import useDebounce from "src/hooks/useDebounce";
import useFetch from "src/hooks/useFetch";
import { manageWorkspaceSettings } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import UsersList from "src/components/Users/UsersList";
import WorkspaceSSO from "src/components/Workspaces/Workspace/SSO/WorkspaceSSO";
import FormList from "src/components/Forms/FormList";
import { H1 } from "src/components/Shared/Text/Headers";
import SiteEmailManager from "src/components/Site/EmailProvider/SiteEmailManager";
import WorkspaceBillingAndPayments from "src/components/Workspaces/Workspace/BillingAndPayments/WorkspaceBillingAndPayments";

const WorkspaceEdit = ({ ...props }) => {
  const spanRef = useRef(null);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [reduxWorkspaceLoading, setReduxWorkspaceLoading] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [name, setName] = useState("");
  const [nameChanges, setNameChanges] = useState(0);

  const [tagList, setTagList] = useState([]);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const queryValue = queryParams.get("tab");

  const [inputWidth, setInputWidth] = useState(1);

  const {
    response: { data: workspace },
    refreshData: refreshWorkspace,
    status: { fail: isWorkspaceLoadedfail },
  } = useFetch(`/workspaces/:workspace_id`, { params: { workspace_id: id } });

  const handleWorkspaceUpdate = async () => {
    await props.manageWorkspaceSettings({ workspace_id: id, name }, "/workspaces/:workspace_id/settings/name");
  };

  const [debouncedNameUpdate, debounceNameStatus] = useDebounce(handleWorkspaceUpdate, 500);

  useEffect(() => {
    if (workspace) {
      setName(workspace?.name || "");
    }
  }, [workspace]);

  useEffect(() => {
    setTagList(props?.workspaceDetails?.tags || []);
  }, [props?.workspaceDetails?.tags]);

  useEffect(() => {
    if (id) {
      (async () => {
        setReduxWorkspaceLoading(true);

        try {
          await props.getWorkspaceDetails({ workspace_id: id });
        } catch (err) {
        } finally {
          setReduxWorkspaceLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (workspace?._id && props.me?._id) {
      let tabs = [];
      if (authorizeUserComponentAccess(props.me, id, "user", [], true) || authorizeUserComponentAccess(props.me, id, "group", ["user_update"], true)) {
        tabs.push({
          name: "Users",
          icon: UsersIcon,
          component: (
            <UsersList
              workspaceId={id}
              workspaceDetails={workspace}
              pageName={"workspace"}
            />
          ),
          hidden: !(authorizeUserComponentAccess(props.me, id, "user", [], true) || authorizeUserComponentAccess(props.me, id, "group", ["user_update"], true)),
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "page", [], true)) {
        tabs.push({
          name: "Pages",
          icon: WindowIcon,
          component: (
            <PageList
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "page", [], true),
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "form", [], true) && workspace?.workspace_type !== "JWT_FULL_EMBED") {
        tabs.push({
          name: "Forms",
          icon: ClipboardDocumentListIcon,
          component: (
            <FormList
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "form", [], true) || workspace?.workspace_type === "JWT_FULL_EMBED",
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "group", [], true)) {
        tabs.push({
          name: "Groups",
          icon: SquaresPlusIcon,
          component: (
            <WorkspaceGroups
              workspaceId={id}
              workspaceDetails={workspace}
              pageName={"workspace"}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "group", [], true),
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "permission", [], true) && workspace?.workspace_type !== "JWT_FULL_EMBED") {
        tabs.push({
          name: "Editors",
          icon: ShieldCheckIcon,
          component: (
            <WorkspacePermissions
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "permission", [], true) || workspace?.workspace_type === "JWT_FULL_EMBED",
        });
      }
      if (workspace?.workspace_type !== "IFRAME_EMBED") {
        tabs.push({
          name: "PDP",
          icon: LockClosedIcon,
          component: (
            <WorkspacePdpList
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: workspace?.workspace_type === "IFRAME_EMBED",
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "settings", [], true) || authorizeUserComponentAccess(props.me, id, "layout", [], true) || authorizeUserComponentAccess(props.me, id, "alias", [], true)) {
        tabs.push({
          name: "Layout & Styles",
          icon: PaintBrushIcon,
          component: (
            <WorkspaceLayoutStyles
              workspaceId={id}
              setWorkspace={refreshWorkspace}
              workspace={workspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "settings", [], true) && !authorizeUserComponentAccess(props.me, id, "layout", [], true) && !authorizeUserComponentAccess(props.me, id, "alias", [], true),
        });
      }
      if (props?.workspaceDetails?.domain && props?.workspaceDetails?.use_workspace_sso && (authorizeUserComponentAccess(props.me, id, "settings", [], true) || authorizeUserComponentAccess(props.me, id, "sso", [], true))) {
        tabs.push({
          name: "SSO",
          icon: IdentificationIcon,
          component: <WorkspaceSSO workspaceId={id} />,
          hidden: !props?.workspaceDetails?.domain && !props?.workspaceDetails?.use_workspace_sso && !authorizeUserComponentAccess(props.me, id, "settings", [], true) && !authorizeUserComponentAccess(props.me, id, "sso", [], true),
        });
      }
      if (props?.workspaceDetails?.use_workspace_smtp && (authorizeUserComponentAccess(props.me, id, "settings", [], true) || authorizeUserComponentAccess(props.me, id, "smtp", [], true))) {
        tabs.push({
          name: "Email Provider",
          icon: EnvelopeIcon,
          component: <SiteEmailManager workspaceId={id} />,
          hidden: !props?.workspaceDetails?.use_workspace_smtp && !authorizeUserComponentAccess(props.me, id, "settings", [], true) && !authorizeUserComponentAccess(props.me, id, "smtp", [], true),
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "billing_and_payment", [], true)) {
        tabs.push({
          name: "Billing",
          icon: BanknotesIcon,
          component: (
            <WorkspaceBillingAndPayments
              workspaceId={id}
              workspaceDetails={workspace}
            />
          ),
          hidden: !authorizeUserComponentAccess(props.me, id, "billing_and_payment", [], true) || !props?.workspaceDetails?.plan || props?.workspaceDetails?.plan?.pricing?.type === "no_charge",
        });
      }
      if (authorizeUserComponentAccess(props.me, id, "integration", [], true) || authorizeUserComponentAccess(props.me, id, "settings", [], true) || authorizeUserComponentAccess(props.me, id, "workspace", [], true)) {
        tabs.push({
          name: "Settings",
          icon: Cog8ToothIcon,
          component: <WorkspaceSettings workspaceId={id} />,
          hidden: !authorizeUserComponentAccess(props.me, id, "integration", [], true) && !authorizeUserComponentAccess(props.me, id, "settings", [], true) && !authorizeUserComponentAccess(props.me, id, "workspace", [], true),
        });
      }
      setTabs(tabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.me?._id, workspace?._id, props?.workspaceDetails?.use_workspace_smtp, props?.workspaceDetails?.use_workspace_sso, props?.workspaceDetails?.domain, props?.workspaceDetails?.plan?.pricing?.type]);

  const loadingColors = {
    IDLE: "group-focus:text-transparent",
    WAITING: "group-focus:text-gray-700/50",
    DONE: "group-focus:text-green-700/50",
  };

  useEffect(() => {
    refreshWorkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (workspace) {
      if (spanRef.current) {
        setInputWidth(spanRef.current.offsetWidth);
      }
    }
  }, [name, workspace]);

  if (
    queryValue &&
    !queryValue.toLowerCase().includes("editors") &&
    !queryValue.toLowerCase().includes("workspace") &&
    !queryValue.toLowerCase().includes("layout") &&
    !queryValue.toLowerCase().includes("email") &&
    !queryValue.toLowerCase().includes("setting") &&
    !queryValue.toLowerCase().includes("billing") &&
    !queryValue.toLowerCase().includes("pdp")
  ) {
    let existsPath = props.menus.some((obj) => {
      return obj.path.includes(queryValue.toLowerCase());
    });
    if (queryValue === "Pages" && (authorizeUserComponentAccess(props.me, id, "page", [], true) || authorizeUserComponentAccess(props.me, id, "group", ["user_update"], true))) {
      existsPath = true;
    }
    if (queryValue === "Users" && (authorizeUserComponentAccess(props.me, id, "user", [], true) || authorizeUserComponentAccess(props.me, id, "group", ["user_update"], true))) {
      existsPath = true;
    }
    // const existsPath = props.menus.some(obj => obj.path === queryParams.get("tab"));
    if (!(props.me?.type === "editor" && props.me?.workspaces.length === 1) && Object.keys(props.menus).length && !existsPath) {
      navigate("/");
    }
  }

  if (!props?.me?._id) return <></>;
  if (props?.me?.type === "editor" && !props.me.workspaces.includes(id)) {
    if (!Array.isArray(props.me.workspaces)) {
      window.location.href = "/workspaces";
    }

    if (!props.me.workspaces.length) {
      window.location.href = "/workspaces";
    }

    const workspaceIds = props.me.workspaces.map((item) => item._id);

    if (!workspaceIds.includes(id)) {
      window.location.href = "/workspaces";
    }
  }

  if (queryValue === "Billing" && props?.workspaceDetails?.plan?.pricing?.type === "no_charge") {
    navigate("/workspaces");
  }

  if (reduxWorkspaceLoading) {
    return <Preloader />;
  }

  if (isWorkspaceLoadedfail) {
    navigate("/");
  }

  const imageSource = (() => {
    const logo = props?.workspaceDetails?.image_logo;
    if (logo instanceof File || logo instanceof Blob) {
      return URL.createObjectURL(logo);
    }
    if (typeof logo === "string") {
      if (logo.includes(".s3.")) {
        return `${logo}?${Date.now()}`;
      }
      if (logo.startsWith("data:image/")) {
        return logo;
      }
      return `${baseUrl}${logo}`;
    }
    return props?.workspaceDetails?.image_logo;
  })();

  return (
    <div className="grid h-full w-full gap-y-4 md:gap-y-0">
      {workspace && (
        <H1
          item={{ icon: (props?.me?.type === "admin" || props?.me?.workspaces?.length > 1) && workspace?._id ? BackIndicator : null }}
          className="flex-nowrap gap-y-2 sm:gap-y-0">
          <div className="lg:max-w-auto relative flex w-full max-w-[200px] flex-row items-center gap-2 lg:gap-4">
            <div
              onClick={() => {
                if (authorizeUserComponentAccess(props.me, id, "layout", [], true)) {
                  navigate({ pathname: location.pathname, search: "tab=Layout+%26+Styles" });
                }
              }}
              style={{ backgroundColor: props?.workspaceDetails?.top_bar_color ? props?.workspaceDetails?.top_bar_color : workspace?.top_bar_color }}
              className={`group relative h-16 w-40 flex-shrink-0 rounded-md border border-gray-200/60 bg-transparent p-1 ${authorizeUserComponentAccess(props.me, id, "layout", [], true) ? "cursor-pointer hover:border-highlightColor" : ""}`}>
              {workspace?._id && (
                <img
                  style={{ display: "block" }}
                  src={imageSource || noLogo}
                  alt="Logo"
                  className="h-full w-full min-w-[80px] object-contain sm:min-w-0"
                  onLoad={(e) => (e.target.style.display = "block")}
                  onError={(e) => (e.target.style.display = "none")}
                />
              )}
              {authorizeUserComponentAccess(props.me, id, "layout", [], true) && (
                <div className="absolute -right-4 -top-4 z-20 rounded-full bg-highlightColor opacity-0 transition-all duration-75 group-hover:opacity-80">
                  <CameraIcon className="h-7 w-7 p-1 text-white" />
                </div>
              )}
            </div>

            <div className="flex w-full lg:w-auto">
              <div
                className="group relative w-full min-w-full flex-shrink pr-3 lg:min-w-min lg:max-w-[60vw] lg:pr-0"
                style={{ width: `${inputWidth}px` }}>
                <div className={classNames("py-.5 absolute -top-3 right-2 flex items-center gap-x-2 overflow-hidden p-1 px-1 font-light transition-all duration-75 sm:-right-3", loadingColors[debounceNameStatus])}>
                  {debounceNameStatus === "WAITING" && (
                    <p className="flex items-center rounded-full bg-gray-500/20">
                      <ArrowPathIcon className="h-5 w-5 animate-spin text-white" />
                    </p>
                  )}
                  {debounceNameStatus === "DONE" && (
                    <p className="flex items-center rounded-full bg-green-600/40 text-white">
                      <CheckCircleIcon className="h-5 w-5 text-white" />
                    </p>
                  )}
                </div>
                <div className={classNames("absolute right-0 top-0 z-[1] flex h-full space-x-1 px-2 py-2", nameChanges > 0 ? "visible opacity-100" : "invisible opacity-0")}>
                  <Button
                    version="gray"
                    onClick={() => {
                      setName(props?.workspaceDetails?.name || "");
                      setNameChanges(0);
                    }}
                    disabled={nameChanges < 1}
                    className="!h-8 !px-2 !py-1 !text-sm 2xl:!h-10">
                    Undo
                  </Button>
                  <Button
                    onClick={() => {
                      setNameChanges(0);
                      debouncedNameUpdate();
                    }}
                    disabled={nameChanges < 1}
                    className="!h-8 !px-2 !py-1 !text-sm 2xl:!h-10">
                    Save
                  </Button>
                </div>
                <Input
                  type="text"
                  autoComplete="on"
                  name="workspace-name"
                  disabled={!authorizeUserComponentAccess(props.me, id, "layout", ["update"], true)}
                  value={name}
                  onChange={(e) => {
                    setNameChanges((prevData) => prevData + 1);
                    setName(e.target.value);
                  }}
                  wrapperClass={"!shadow-none"}
                  inputClassNames={classNames(
                    `w-full focus:ring-0 focus:border-highlightColor bg-transparent border border-transparent flex items-center gap-x-2 text-3xl px-2 py-1 rounded transition-all duration-75 font-thin text-gray-400 ${
                      authorizeUserComponentAccess(props.me, id, "layout", ["update"], true) ? "hover:border-gray-400/20" : ""
                    }`,
                    nameChanges > 0 ? "!border-gray-400/20" : "",
                  )}
                  autoGrowWidth={true}
                />
                <span
                  ref={spanRef}
                  className="invisible absolute right-0 whitespace-pre px-2 py-1 pr-32 text-3xl font-thin">
                  {name}sdaf
                </span>
              </div>
            </div>

            <div className="hidden flex-shrink-0 flex-wrap items-center gap-4 sm:flex">
              {tagList?.length > 0 && (
                <div className="flex flex-wrap items-center gap-2">
                  <p className="text-base font-normal text-gray-300">Tags:</p>
                  {tagList.map((tag, index) => {
                    return (
                      <PillEntry
                        index={index}
                        key={tag}
                        tag={tag}
                        deleteEnabled={false}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </H1>
      )}
      {/* <div className="mb-4 flex h-full w-full items-center justify-between md:mb-6">
        <div className="relative flex w-full items-center gap-x-2 lg:gap-x-4">
          {(props?.me?.type === "admin" || props?.me?.workspaces?.length > 1) && workspace?._id && (
            <div>
              <BackIndicator />
            </div>
          )}

        </div>
      </div> */}
      {tabs.length > 0 && <Tabs tabs={tabs} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
    menus: Object.values(state.menus),
    workspaceDetails: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, { getAuthorizedUser, getWorkspaceDetails, manageWorkspaceSettings })(WorkspaceEdit);
